import { routesBuilder, StatusCodes, yup } from '@guiker/shared-framework'

import { getYearMonthSchema } from '../schema-utils'

const { buildGet } = routesBuilder<object>()({ basePath: '/webhook/statistics' })

export const routes = {
  exportRentPaymentsReconciliation: buildGet({
    path: '/rent-payments/export-reconciliation',
    queryParamsSchema: getYearMonthSchema('month', false).concat(yup.object({ secret: yup.string().required() })),
    successStatusCode: StatusCodes.REDIRECT,
    response: null as {},
  }),
}
