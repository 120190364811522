import { routesBuilder } from '@guiker/shared-framework'

import { RentalServiceFee } from '../../../entity'
import { paths } from '../paths'
import { leaseTypePayloadSchema } from '../schemas'

const { buildPost, buildGet } = routesBuilder<RentalServiceFee>()({ basePath: paths.user.withId.rentalServiceFee.path })

export const routes = {
  upsertRentalServiceFee: buildPost({
    payloadSchema: leaseTypePayloadSchema,
  }),
  readRentalServiceFeeByUserId: buildGet({}),
}
