import { AdminApiClient } from '@guiker/payment-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'

export const {
  useContext: useAdminFundingAccountContext,
  Context: AdminFundingAccountContext,
  ContextProvider: AdminFundingAccountContextProvider,
  NestedRouter: AdminFundingAccountNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.ADMIN,
  apiClientGen: AdminApiClient,
  cacheKey: ({ userId }: { userId: string }) => `readOneFundingAccountByUser-${userId}`,
  fetcher: (apiClient, { userId }: { userId: string }) => {
    return apiClient.readFundingAccountByUser({ pathParams: { userId } })
  },
  renderLoadingSpinner: false,
})

export const {
  useContext: useAdminWithdrawalContext,
  Context: AdminWithdrawalContext,
  ContextProvider: AdminWithdrawalContextProvider,
  NestedRouter: AdminWithdrawalNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.ADMIN,
  apiClientGen: AdminApiClient,
  cacheKey: ({ withdrawalId }: { withdrawalId: string }) => `readOneFundingAccountWithdrawalById-${withdrawalId}`,
  fetcher: (apiClient, { withdrawalId }: { withdrawalId: string }) => {
    return apiClient.readOneWithdrawal({ pathParams: { id: withdrawalId } })
  },
})
