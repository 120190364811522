import { yup } from '@guiker/shared-framework'

export const getYearMonthSchema = (property: string, required = true) => {
  const schema = yup.string().test('invalidDate', 'must be "yyyy-MM" format', (value?: string) => {
    const yearDateRegex = new RegExp(`^[0-9]{4}-[0-9]{2}$`, 'g')
    return !value || yearDateRegex.test(value)
  })

  return yup.object({ [property]: required ? schema.required() : schema.nullable() })
}
