import React, { useState } from 'react'

import { getUserFullName } from '@guiker/base-entity'
import {
  Applicant,
  Booking,
  BookingStatus,
  getApplicantFirstName,
  getApplicantFullName,
  getApplicantLastName,
} from '@guiker/booking-shared'
import { Collapse, Divider, Flex, RotatingIcon, RouterLink, TextButton } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { UserInformation } from './UserInformation'

type ApplicantListProps = {
  booking: Booking
}

type ApplicantListItemProps = {
  applicant: Applicant
  booking: Booking
}

const ApplicantListItem: React.FC<ApplicantListItemProps> = ({ applicant, booking }) => {
  const { tBase } = useT()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Flex flexDirection='column'>
      <Flex gap={2}>
        <UserInformation
          firstName={getApplicantFirstName(applicant) || applicant.firstName}
          lastName={getApplicantLastName(applicant) || applicant.lastName}
          avatarUrl={applicant.avatarUrl}
          emailAddress={applicant.emailAddress}
          userId={applicant.userId}
          fullName={getApplicantFullName(applicant) || getUserFullName(applicant)}
        />
        <Flex gap={2} alignItems='center' alignSelf='flex-start'>
          {booking.hasEnabledStep.payment && booking.status === BookingStatus.BOOKED && (
            <RouterLink to={`./tenants/${applicant.userId}`} prefixWithLocale={false}>
              <TextButton>{tBase('actions.view')}</TextButton>
            </RouterLink>
          )}
          <RotatingIcon isRotated={isOpen} onClick={() => setIsOpen((curr) => !curr)} />
        </Flex>
      </Flex>
      <Collapse in={isOpen}></Collapse>
    </Flex>
  )
}

export const ApplicantList: React.FC<ApplicantListProps> = ({ booking }) => {
  return (
    <Flex flexDirection='column' gap={1}>
      {booking.applicants.map((applicant, index) => (
        <>
          <ApplicantListItem applicant={applicant} booking={booking} />
          {index < booking.applicants.length - 1 && <Divider />}
        </>
      ))}
    </Flex>
  )
}
