import { buildPaginationQueryParamSchema } from '@guiker/paginate'
import { yup } from '@guiker/yup-util'

import { BookingStatus } from '../../../entity'

export const readAllBookingsForPropertyOwnerQueryParamsSchema = buildPaginationQueryParamSchema().concat(
  yup.object({
    userId: yup.string().required(),
    statuses: yup
      .array()
      .of<BookingStatus>(yup.mixed<BookingStatus>())
      .nullable()
      .transform((v) => (!!v ? v : null)),
    searchString: yup.string().nullable(),
  }),
)

export type ReadAllBookingsForPropertyOwnerQueryParamsSchema = yup.InferType<
  typeof readAllBookingsForPropertyOwnerQueryParamsSchema
>
