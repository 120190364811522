/**
 * @param types <D, T, K>: D = Array data type, T = Return type, K = Key type
 */
export const reduceToMap = <D, T = D, K extends string = string>(
  data: D[],
  callback: (acc: Record<K, T>, curr: D, index: number, array: D[]) => Record<K, T>,
  initialValue: Record<K, T> = {} as Record<K, T>,
): Record<K, T> => {
  return data.reduce(callback, initialValue)
}
