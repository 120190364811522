import React from 'react'

import { Button, Modal, P, useT } from '@guiker/react-framework'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const MicrodepositInstructionModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { tBase, tMain } = useT({ domain: 'payment', screenName: 'screens.microdepositModal' })

  return (
    <Modal
      minWidth={'50%'}
      open={isOpen}
      onClose={onClose}
      title={tMain('title')}
      disableEnforceFocus={true}
      disableBackdropClick
      actions={
        <Button onClick={onClose} size='small'>
          {tBase('actions.ok')}
        </Button>
      }
    >
      <P>{tMain('description')}</P>
    </Modal>
  )
}
