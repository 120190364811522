import { PhoneNumberSchema } from '@guiker/phone-number'
import { yup } from '@guiker/shared-framework'

import { ManagerRole } from '../../../../entity'

const ownerSchema = yup.object<{
  userId?: string
  lastName?: string
  firstName: string
  emailAddress: string
}>({
  userId: yup.string().nullable(),
  lastName: yup.string().nullable(),
  firstName: yup.string().required(),
  emailAddress: yup.string().required(),
})

const managerSchema = yup.object({
  userId: yup.string().nullable(),
  emailAddress: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().nullable(),
  role: yup.string().required().oneOf(Object.values(ManagerRole)),
  isContactPerson: yup.boolean().required(),
  phone: PhoneNumberSchema(false).required(),
  title: yup.string().nullable(),
})

export const transferOwnershipSchema = yup.object({
  owner: ownerSchema.required(),
  managers: yup.array().of(managerSchema).required(),
})
