import { routesBuilder } from '@guiker/api-definition'
import { RecommendedListing } from '@guiker/recommended-listing-entity'

import { paths } from './paths'

const { buildGet } = routesBuilder<RecommendedListing>()({ basePath: paths.recommendedListing.all.base() })

export const routes = {
  readAllRecommendedListingPerCity: buildGet({
    path: '/cities/:cityId',
    paginated: true,
  }),
  readAllRecommendedListingPerNeighbourhood: buildGet({
    path: '/cities/:cityId/neighbourhoods/:neighbourhoodSlug',
    paginated: true,
  }),
  readAllRecommendedListingsByListingId: buildGet({
    path: '/listings/:listingId',
    paginated: true,
  }),
}
