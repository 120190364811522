import React from 'react'

import { FundingAccountWithdrawalProjection, FundingAccountWithdrawalStatus } from '@guiker/payment-shared'
import { PaginatedTable, PaginatedTableProps, Pagination } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { useGetColumns } from './get-columns'

const StatusMap = {
  success: [FundingAccountWithdrawalStatus.COMPLETED],
  processing: [FundingAccountWithdrawalStatus.PROCESSING],
  bad: [FundingAccountWithdrawalStatus.FAILED],
}

type FundingAccountWithdrawalsTableProps<
  StatusGroupName extends string = never,
  TabValue extends object = never,
> = Pick<
  PaginatedTableProps<FundingAccountWithdrawalProjection, StatusGroupName, FundingAccountWithdrawalStatus, TabValue>,
  'queryKey' | 'fetcher' | 'tabs' | 'exportAsCSV' | 'onRowClick'
> & {
  withSearch?: boolean
  withGroups?: boolean
  pagination?: Partial<Pagination>
}

export const FundingAccountWithdrawalsTable = <
  StatusGroupName extends string = never,
  TabValue extends object = never,
>({
  exportAsCSV,
  fetcher,
  pagination,
  queryKey,
  tabs,
  onRowClick,
  withGroups = true,
  withSearch = true,
}: FundingAccountWithdrawalsTableProps<StatusGroupName, TabValue>) => {
  const { tShared } = useT({ entity: 'fundingAccount.withdrawal' })
  const columns = useGetColumns({ callbackArgs: { showNote: true } })

  return (
    <PaginatedTable
      exportAsCSV={exportAsCSV}
      queryKey={queryKey}
      fetcher={fetcher}
      tabs={tabs}
      search={withSearch}
      pagination={pagination}
      onRowClick={onRowClick}
      statusGroups={
        withGroups
          ? {
              mapper: StatusMap,
              groups: [
                { name: 'all', label: tShared('statusGroup.all') },
                { name: 'success', label: tShared('statusGroup.success') },
                { name: 'processing', label: tShared('statusGroup.processing') },
                { name: 'bad', label: tShared('statusGroup.bad') },
              ],
            }
          : undefined
      }
      columns={columns}
    />
  )
}
