import { buildPaginationQueryParamSchema } from '@guiker/paginate'
import { yup } from '@guiker/yup-util'

export const readAllPropRentalsQueryParamsSchema = buildPaginationQueryParamSchema().concat(
  yup.object<{
    searchString?: string
    userId?: string
  }>({
    searchString: yup.string().nullable(),
    userId: yup.string().nullable(),
  }),
)
