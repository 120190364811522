import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { optionalConcat } from '@guiker/lodash'
import { Invoice, isInvoiceFailed, isInvoicePayable, isInvoiceRecoverable } from '@guiker/payment-shared'
import { Chip, ChipProps, Flex, useDateFormatter } from '@guiker/react-framework'

import { useT } from '../../../i18n'
import { getInvoiceColor, useGetInvoiceStatus } from '../../../utils/invoice-utils'

type Attributes = 'scope' | 'dueBy' | 'createdAt' | 'status' | 'updatedAt' | 'transactions' | 'dispute' | 'customer'
type InvoiceDetailStatusChipProps = Pick<ChipProps, 'size' | 'variant'> & {
  invoice: Pick<Invoice, Attributes>
  withDate?: boolean
  direction?: 'row' | 'column'
}
type DisputeChipProps = Pick<ChipProps, 'size' | 'variant'> & {
  invoice: Pick<Invoice, Attributes>
}

const DisputeChip: React.FC<DisputeChipProps> = ({ size, variant, invoice }) => {
  const { user } = useAuthenticationContext()
  const { tShared } = useT({ entity: 'invoice.disputed' })

  if (!invoice.dispute) return <></>

  const isPayer = invoice?.customer.id === user?.id
  const showStatus = !isPayer || !isInvoiceRecoverable(invoice as Invoice)
  const status = invoice.dispute.status

  const label = tShared(isPayer ? 'payer.label' : 'label')
  const statusLabel = showStatus ? tShared(isPayer ? `payer.${status}` : status) : undefined

  return (
    <Chip variant={variant} size={size} color={'alert'}>
      {optionalConcat([label, statusLabel], ' | ')}
    </Chip>
  )
}

export const InvoiceDetailStatusChip: React.FC<InvoiceDetailStatusChipProps> = ({
  invoice,
  size = 'small',
  variant = 'filled',
  direction = 'row',
  withDate,
}) => {
  const statusLabel = useGetInvoiceStatus()(invoice)
  const { formatDate } = useDateFormatter()
  const isPast = new Date(invoice.dueBy || invoice.createdAt) < new Date()
  const isOverdue = !isInvoiceFailed(invoice) && isInvoicePayable(invoice) && isPast
  const date = formatDate(invoice.transactions[invoice.transactions.length - 1]?.createdAt || invoice.updatedAt)

  return (
    <Flex flexDirection={direction} gap={1}>
      <Chip variant={variant} size={size} color={getInvoiceColor(invoice)}>
        {withDate && !isOverdue ? `${statusLabel} | ${date}` : statusLabel}
      </Chip>
      <DisputeChip variant={variant} size={size} invoice={invoice} />
    </Flex>
  )
}
