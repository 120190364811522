import { yup } from '@guiker/shared-framework'

import { AgreementFeeType } from '../../../entity'

const rentalFeeSchema = yup.object({
  type: yup.string().oneOf([AgreementFeeType.PERCENTAGE]).default(AgreementFeeType.PERCENTAGE).required(),
  value: yup.number().required(),
})

export const leaseTypePayloadSchema = yup.object({
  newLease: rentalFeeSchema.required(),
  renewal: rentalFeeSchema.required(),
  sublease: rentalFeeSchema.required(),
})
