import { HttpMethod } from '@guiker/http'

const STRIPE_PATH = '/webhook/stripe'
const PAYSAFE_PATH = '/webhook/paysafe'

export const routes = {
  handleChargeConnectUS: {
    path: `${STRIPE_PATH}/connect/charge`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as any),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handleChargeDirectUS: {
    path: `${STRIPE_PATH}/direct/charge`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as any),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handleDisputeDirectUS: {
    path: `${STRIPE_PATH}/direct/dispute`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as any),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handleSetUpIntentDirectUS: {
    path: `${STRIPE_PATH}/direct/set-up-intent`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as any),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handleSetUpIntentDirectCA: {
    path: `${STRIPE_PATH}/ca/direct/set-up-intent`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as any),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handleChargeConnectCA: {
    path: `${STRIPE_PATH}/ca/connect/charge`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as any),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handleChargeDirectCA: {
    path: `${STRIPE_PATH}/ca/direct/charge`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as any),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handleDisputeDirectCA: {
    path: `${STRIPE_PATH}/ca/direct/dispute`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as any),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handlePaysafeEFT: {
    path: `${PAYSAFE_PATH}/eft`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as any),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
}
