import React, { useState } from 'react'

import { isAtLeastTablette } from '@guiker/components-core'
import { Flex, isMobile, makeStyles, PBold } from '@guiker/components-library'
import { SupportedFileType } from '@guiker/dropzone'
import { compact } from '@guiker/lodash'
import { DragAndDropProvider, GridItem, update } from '@guiker/react-drag-and-drop'

import { AssetFile } from '../../entity'
import { fileToAssetFile } from '../../utils'
import { Asset } from '../Asset'
import { UploadAssetButton } from '../UploadAssetButton'

export type BaseAssetContainerProps = {
  assetFlexDirection?: 'row' | 'column'
  assets: AssetFile[]
  disableDragAndDrop?: boolean
  displayDirection?: 'row' | 'column'
  displayNameEditable?: boolean
  dropzoneOptions?: {
    accept?: SupportedFileType | SupportedFileType[]
    maxFiles?: number
    maxSize?: number
  }
  fullWidth?: boolean
  name: string
  additionalFields?: {
    name: string
    prop: keyof AssetFile
  }[]
  onAddAsset?: (asset: AssetFile) => void
  onMoveAsset?: (dragIndex: number, hoverIndex: number) => void
  onRemoveAsset?: (index: number) => void
  onChangeOrder?: (updatedAssets: AssetFile[]) => void
  readOnly?: boolean
  showDropzone?: boolean
  withThumbnail: boolean
}

const useStyles = makeStyles(
  {
    assetContainer: ({ flexDirection }: { flexDirection: 'column' | 'row' }) => ({
      [isMobile]: {
        flexDirection: 'column',
      },
      [isAtLeastTablette]: {
        flexDirection,
      },
    }),
  },
  { name: 'BaseAssetContainer' },
)

export const BaseAssetContainer: React.FC<BaseAssetContainerProps> = ({
  assetFlexDirection = 'column',
  assets: propAssets = [],
  dropzoneOptions,
  displayDirection,
  displayNameEditable,
  disableDragAndDrop,
  fullWidth = false,
  name,
  additionalFields,
  onAddAsset,
  onChangeOrder,
  onMoveAsset,
  onRemoveAsset,
  readOnly,
  showDropzone = true,
  withThumbnail,
}) => {
  const classes = useStyles({ flexDirection: displayDirection })
  const assets = compact(propAssets)
  const [ordered, setOrdered] = useState<AssetFile[]>()

  const onDropzoneDrop = (file: File) => {
    const asset = fileToAssetFile(file, `${name}[${assets.length + 1}]`)

    onAddAsset?.(asset)
  }

  const reorderAssets = (dragIndex: number, hoverIndex: number) => {
    const dragItem = assets[dragIndex]
    return update(assets, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, { ...dragItem, id: dragItem.id }],
      ],
    })
  }

  const onGridDrag = (dragIndex: number, hoverIndex: number) => {
    setOrdered(reorderAssets(dragIndex, hoverIndex))
    onMoveAsset?.(dragIndex, hoverIndex)
  }

  const onGridDrop = () => {
    ordered && onChangeOrder?.([...ordered])
    setOrdered(undefined)
  }

  if (assets.length === 0 && readOnly) {
    return (
      <PBold px={1} mb={0}>
        -
      </PBold>
    )
  }

  return (
    <Flex gap={assets.length !== 0 ? 3 : 0} flexDirection='column'>
      <DragAndDropProvider>
        <Flex gap={3} flexWrap='wrap' className={classes.assetContainer}>
          {(ordered ?? assets).map((asset, index) => (
            <GridItem
              index={index}
              key={asset.id || index}
              id={asset.id || `${index}`}
              onDrag={onGridDrag}
              onDrop={onGridDrop}
              canDrag={!readOnly && !disableDragAndDrop}
            >
              <Asset
                flexDirection={assetFlexDirection}
                id={asset.id}
                asset={asset}
                readOnly={readOnly}
                displayNameEditable={displayNameEditable}
                index={index}
                name={`${name}[${index}]`}
                additionalFields={additionalFields}
                removeAsset={() => onRemoveAsset(index)}
                withThumbnail={withThumbnail}
              />
            </GridItem>
          ))}
        </Flex>
      </DragAndDropProvider>
      {!readOnly && showDropzone && (
        <UploadAssetButton
          fullWidth={fullWidth}
          dropzoneOptions={{ ...dropzoneOptions, onDrop: onDropzoneDrop }}
          disableDragAndDrop={disableDragAndDrop}
        />
      )}
    </Flex>
  )
}
