import React, { createContext, useEffect, useState } from 'react'

import { PayInMethod, PayInMethodType, SupportedCountries } from '@guiker/payment-shared'
import { useModal, useQueryMutation } from '@guiker/react-framework'

import { usePaymentApiClient } from '../hooks'

type ModalButtonType = 'p' | 'button'

type Context = {
  allowedTypes: PayInMethodType[]
  isLoading: boolean
  modalManager: {
    buttonType: {
      value: ModalButtonType
      setType: (size: ModalButtonType) => void
    }
    addCreditCard: ReturnType<typeof useModal>
    addDirectDebit: ReturnType<typeof useModal>
  }
  payInMethods: PayInMethod[]
  region: SupportedCountries
  selected?: PayInMethod
  setSelectedById: (payInMethodId: string) => void
  onPaymentMethodRemoved: (id: string) => void
  onPaymentMethodAdded: (payInMethod: PayInMethod) => void
}

const defaultContext = {
  payInMethods: [],
} as Context

const PayInMethodContext = createContext<Context>(defaultContext)

type ProviderProps = React.PropsWithChildren & {
  region: SupportedCountries
  allowedTypes: PayInMethodType[]
  selectedId?: string
  enabled?: boolean
}

const PayInMethodContextProvider: React.FC<ProviderProps> = ({
  children,
  region: propRegion,
  allowedTypes: propAllowedTypes,
  selectedId,
  enabled = true,
}) => {
  const [allowedTypes, setAllowedTypes] = useState<PayInMethodType[]>(propAllowedTypes)
  const [selected, setSelected] = useState<PayInMethod | undefined>(undefined)
  const [region, setRegion] = useState<SupportedCountries>(propRegion)
  const [buttonType, setButtonType] = useState<ModalButtonType>('p')
  const apiClient = usePaymentApiClient()
  const { useQuery, setQueryData } = useQueryMutation<PayInMethod[]>({ queryKey: ['readAllPayInMethods'] })

  const modalManager = {
    buttonType: { value: buttonType, setType: setButtonType },
    addCreditCard: useModal(),
    addDirectDebit: useModal(),
  }

  const { data: payInMethods, isLoading } = useQuery(
    () => {
      return apiClient
        .readAllPayInMethods()
        .then((payInMethods) => payInMethods?.filter((p) => p.region === region && allowedTypes.includes(p.type)))
    },
    {
      enabled,
      onSuccess: (payInMethods) => {
        if (!selected) {
          const selectedFromId = selectedId
            ? payInMethods.find((p) => selectedId === (isFinite(selectedId as unknown as number) ? p.legacyId : p.id))
            : payInMethods[0]

          !selectedFromId?.disabled && setSelected(selectedFromId)
        }
      },
    },
  )

  const onPaymentMethodRemoved = (id: string) => {
    setSelected(undefined)
    setQueryData(payInMethods.filter((pms) => pms.id !== id))
  }

  const onPaymentMethodAdded = (paymentMethod: PayInMethod) => {
    const { disabled } = paymentMethod

    if (!disabled) setSelected(paymentMethod)
    setQueryData([...(payInMethods ?? []), paymentMethod])
  }

  const setSelectedById = (payInMethodId: string) => {
    setSelected(payInMethods?.find((p) => p.id.toString() === payInMethodId && !p.disabled))
  }

  useEffect(() => {
    propRegion !== region && setRegion(propRegion)
  }, [propRegion])

  useEffect(() => {
    propAllowedTypes !== allowedTypes && setAllowedTypes(propAllowedTypes)
  }, [propAllowedTypes])

  const contextValue = {
    allowedTypes,
    isLoading,
    modalManager,
    payInMethods,
    region,
    selected,
    setSelectedById,
    onPaymentMethodRemoved,
    onPaymentMethodAdded,
  }

  return <PayInMethodContext.Provider value={contextValue}>{children}</PayInMethodContext.Provider>
}

export { PayInMethodContext, PayInMethodContextProvider }
