import { LeaseType } from '@guiker/base-entity'
import { TypeOf, yup } from '@guiker/yup-util'

import {
  BookingStatus,
  HasEnabledStep,
  InvitationStatus,
  ParticipantType,
  PaymentCondition,
  ServiceFee,
  ServiceFeeType,
  TIMER_MAX_HRS_EXTENSION,
} from '../../../entity'

export const cancelBookingRequestSchema = yup.object({
  cancelReason: yup.string().required(),
})
export type CancelBookingRequestPayload = TypeOf<typeof cancelBookingRequestSchema>

export const applicantPayloadSchema = yup.object({
  userId: yup.string(),
  emailAddress: yup.string(),
  invitation: yup.object({
    status: yup.mixed<InvitationStatus>().oneOf([InvitationStatus.ACCEPTED, InvitationStatus.INVITED]),
  }),
  firstName: yup.string(),
  lastName: yup.string(),
  avatarUrl: yup.string(),
})

export const createBookingPayloadSchema = yup.object({
  optionId: yup.number().required(),
  applicants: yup.array().of(applicantPayloadSchema).nullable(),
})

export type CreateBookingPayloadSchema = TypeOf<typeof createBookingPayloadSchema>

const bookingHasEnabledStepSchema = {
  hasEnabledStep: yup
    .object<HasEnabledStep>({
      application: yup.boolean().required().default(true),
      roommate: yup.boolean().required().default(false),
      document: yup.boolean().required().default(true),
      lease: yup.boolean().required(),
      payment: yup.boolean().required(),
    })
    .required(),
}

const bookingInfoRequestSchema = {
  price: yup
    .object({
      amount: yup.number().required(),
    })
    .required(),
  period: yup
    .object({
      from: yup.date().required(),
      to: yup.date().required(),
    })
    .required(),
  leaseType: yup.string().oneOf(Object.values(LeaseType)).required(),
}

export const updateBookingSchema = yup.object({
  ...bookingInfoRequestSchema,
  ...bookingHasEnabledStepSchema,
  optionId: yup.number().required(),
  shouldChargeProrataFirst: yup.boolean().when('hasEnabledStep', {
    is: (hasEnabledStep: HasEnabledStep) => hasEnabledStep.payment === true,
    then: yup.boolean().required(),
    otherwise: yup.boolean().transform((value) => value || false),
  }),
  serviceFee: yup
    .object<ServiceFee>({
      value: yup.number().nullable(),
      type: yup.string().oneOf(Object.values(ServiceFeeType)).nullable(),
    })
    .nullable(),
  condition: yup
    .string()
    .oneOf([...Object.values(PaymentCondition), null])
    .when('hasEnabledStep', {
      is: (hasEnabledStep: HasEnabledStep) => hasEnabledStep.payment === true,
      then: yup.string().required(),
      otherwise: yup
        .string()
        .nullable()
        .transform(() => null as null),
    }),
})

export type UpdateBookingSchema = TypeOf<typeof updateBookingSchema>

export const searchBookingQueryParamsSchema = yup.object({
  status: yup.mixed().oneOf(Object.values(BookingStatus)),
  role: yup.mixed().oneOf(Object.values(ParticipantType)),
})

export type SearchBookingQueryParams = TypeOf<typeof searchBookingQueryParamsSchema>

export const extendTimerExpirationPayloadSchema = yup.object({
  expirationHours: yup
    .number()
    .min(1)
    .test('booking.maxTimeExtension', 'Over max time extension (hours)', (value: number) => {
      return value <= TIMER_MAX_HRS_EXTENSION
    })
    .required(),
})
