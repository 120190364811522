export enum Features {
  /** @description temporary features */
  RevokeLesseeSignaturesOnLeaseUpdate = 'RevokeLesseeSignaturesOnLeaseUpdate',
  VerifyCertnBackgroundCheck = 'VerifyCertnBackgroundCheck',
  AutoBackgroundCheckOnBookingApplicationSubmit = 'AutoBackgroundCheckOnBookingApplicationSubmit',
  NewMobileTabsNavigation = 'NewMobileTabsNavigation',
  DarkAppBar = 'DarkAppBar',
  LeaseDocuments = 'LeaseDocuments',
  PromiseToLease = 'PromiseToLease',
  HideLinkToTenantProfile = 'HideLinkToTenantProfile',
  HideLinkToBooking = 'HideLinkToBooking',
  SendRecommendedListingsEmail = 'SendRecommendedListingsEmail',

  /** @description permanant features */
  TestPasswordStrength = 'TestPasswordStrength',
}
