import React from 'react'

import { usePaymentApiClient } from '@guiker/payment-context'
import { Invoice, isInvoiceRecoverable, Scopeless, ScopeType, webappRoutes } from '@guiker/payment-shared'
import { ActivityLabel, ActivityMarker, Link, Source, useQuery, useT } from '@guiker/react-framework'
import { compact, Event, formatDateTime, formatMonthAndYear, selectFromMap } from '@guiker/shared-framework'

export const useTransformToComponentMethod = (invoice: Invoice) => {
  const { tShared, tMain, MainTrans } = useT({ domain: 'payment', screenName: 'screens.activityLog' })
  const paymentApiClient = usePaymentApiClient()

  const invoiceId = invoice.id
  const hasDispute = !!invoice.dispute
  const isRecoverable = isInvoiceRecoverable(invoice)

  const { data: recoveryInvoice } = useQuery<Invoice<ScopeType.DISPUTED_INVOICE>>(
    ['disputedRecoveryInvoice', invoiceId],
    () =>
      paymentApiClient.readLastInvoiceByScope({
        pathParams: { scopeType: ScopeType.DISPUTED_INVOICE, scopeId: invoiceId },
      }),
    { enabled: invoiceId && hasDispute && isRecoverable },
  )

  const reason = !invoice.scope
    ? (invoice as Invoice<Scopeless>).metadata.reason
    : selectFromMap<string>({
        [ScopeType.BUY_STOCK_ORDER]: tMain('buyStockOrder'),
        [ScopeType.FUNDING_ACCOUNT_DEPOSIT]: tMain('fundingAccountDeposit'),
        [ScopeType.TENANT_INSTALMENT]: `${tMain('rentPayment')} ${formatMonthAndYear(invoice.createdAt)}`,
        default: invoice.items[0]?.label,
      })(invoice.scope.type)

  return {
    transform: ({ event }: { event: Event<Invoice> }) => {
      const { emittedAt, type, data: invoice } = event
      const timestamp = formatDateTime(emittedAt)

      switch (type) {
        case 'CREATED':
        case 'IMMEDIATE_CHARGEABLE_CREATED':
          return {
            content: <Source content={timestamp} />,
            label: <ActivityLabel content={tMain(type, { reason })} />,
          }
        case 'CHARGE_PENDING':
        case 'CHARGE_SUCCEED':
          return {
            label: <ActivityLabel color={hasDispute ? 30 : 'textPrimary'} content={tMain(type)} />,
            content: <Source content={timestamp} />,
          }
        case 'CANCELLED':
        case 'VERIFICATION_REQUESTED':
        case 'VERIFICATION_FAILED':
        case 'VERIFICATION_SUCCEED':
        case 'FUNDING_ACCOUNT_CHARGE_PENDING':
        case 'FUNDING_ACCOUNT_CHARGE_SUCCEED':
        case 'FUNDING_ACCOUNT_CHARGE_FAILED':
        case 'STOCK_ORDER_INVOICE_REIMBURSED':
        case 'CHARGE_FAILED':
        case 'OFFLINE_CHARGE_SUCCEED':
        case 'OFFLINE_CHARGE_FAILED':
        case 'OFFLINE_CHARGE_UNDONE':
        case 'REFUND_PENDING':
        case 'REFUND_FAILED':
        case 'REFUND_SUCCEED':
          return {
            label: <ActivityLabel color={'textPrimary'} content={tMain(type)} />,
            content: <Source content={timestamp} />,
          }
        case 'DISPUTE_CREATED':
          const disputeReason = tShared(`invoice.disputed.reason.${invoice.dispute.reason}`)
          const disputeCreatedContent = isRecoverable
            ? tMain('DISPUTE_NSF_CREATED', { reason: disputeReason })
            : tMain('DISPUTE_CREATED')
          return {
            label: <ActivityLabel content={disputeCreatedContent} />,
            content: <Source content={timestamp} />,
          }
        case 'DISPUTE_CLOSED':
          const path = recoveryInvoice ? webappRoutes.root.invoice.byId(recoveryInvoice.id).path : undefined
          const disputeClosedContent =
            isRecoverable && path ? (
              <MainTrans i18nKey={`DISPUTE_NSF_CLOSED_LOST`}>
                <Link color={60} to={path} />
              </MainTrans>
            ) : (
              tMain(`DISPUTE_CLOSED_${invoice.dispute.status}`)
            )

          return {
            content: <Source content={timestamp} />,
            label: <ActivityLabel content={disputeClosedContent} />,
          }
        default: {
          return
        }
      }
    },
    dispute: {
      exists: hasDispute,
      recoveryInvoice,
    },
  }
}

export const useTransformEventsToActivityItems = (args: { invoice: Invoice; events?: Event[] }) => {
  const { invoice, events = [] } = args
  const { transform, dispute } = useTransformToComponentMethod(invoice)
  const filteredEvents = compact(events.map((event) => transform({ event: event as Event<Invoice> })))

  const activityLogItems = filteredEvents?.map((item, index) => ({
    ...item,
    marker: {
      isFirst: index === 0,
      isLast: index === filteredEvents.length - 1,
      icon: <ActivityMarker />,
    },
  }))

  return {
    activityLogItems,
    dispute,
  }
}
