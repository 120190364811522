import { TypeOf, yup } from '@guiker/yup-util'

export const splitBuildingsPayloadSchema = yup.object<{
  buildingId: string
  legacyIds: number[]
}>({
  buildingId: yup.string().required(),
  legacyIds: yup.array(yup.number()).min(1).required(),
})

export type SplitBuildingsPayloadSchema = TypeOf<typeof splitBuildingsPayloadSchema>
