import { routesBuilder } from '@guiker/shared-framework'

import { Agreement, PropRentalProjection } from '../../../entity'
import { paths } from '../paths'
import { routes as idNestedRoutes } from './:id'
import { readAllPropRentalsQueryParamsSchema } from './schemas'

const { buildGet } = routesBuilder<PropRentalProjection>()({ basePath: paths.propRentals.path })
const listingNested = routesBuilder<PropRentalProjection>()({ basePath: paths.listing.withId.propRental.path })

export const routes = {
  ...idNestedRoutes,
  readAllPropRentals: buildGet({
    paginated: true,
    queryParamsSchema: readAllPropRentalsQueryParamsSchema,
  }),
  /** @deprecated remove once booking knows of propRental */
  readOnePropRentalByListingId: listingNested.buildGet({}),
  readOnePropRentalFeeByListingId: listingNested.buildGet({
    path: '/fee',
    response: null as Agreement['fee'],
  }),
  readLegacyIdsForPropertyOwner: buildGet({
    path: '/property-owner/:userId/legacy-ids',
    response: null as number[],
  }),
}
