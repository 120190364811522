import { yup } from '@guiker/yup-util'

export const stripeConfigSchema = yup
  .object({
    apiVersion: yup.string().required(),
    dashboardUrl: yup.string().required(),
    us: yup
      .object({
        secret: yup.string().required(),
        webhookSecret: yup
          .object({
            chargeConnect: yup.string().required(),
            chargeDirect: yup.string().required(),
            payoutConnect: yup.string().required(),
            payoutDirect: yup.string().required(),
            accountConnect: yup.string().required(),
            disputeDirect: yup.string().required(),
            setUpIntentDirect: yup.string().required(),
          })
          .required(),
      })
      .required(),
    ca: yup
      .object({
        secret: yup.string().required(),
        webhookSecret: yup
          .object({
            chargeConnect: yup.string().required(),
            chargeDirect: yup.string().required(),
            payoutConnect: yup.string().required(),
            payoutDirect: yup.string().required(),
            accountConnect: yup.string().required(),
            disputeDirect: yup.string().required(),
            setUpIntentDirect: yup.string().required(),
          })
          .required(),
      })
      .required(),
  })
  .required()
