import { routesBuilder } from '@guiker/api-definition'

import { NEWBuilding, SimilarAddressBuildings, SplitBuilding } from '../../../entity'
import {
  mergeBuildingsPayloadSchema,
  paginationSearchStringQueryParamsSchema,
  splitBuildingsPayloadSchema,
  thresholdQueryParamsSchema,
} from '../schemas'
import { paths } from './paths'

const basePath = paths.NEWBuilding.all.withId(':id')
const baseAllPath = paths.NEWBuilding.all.base()
const { buildGet } = routesBuilder<NEWBuilding>()({ basePath })
const { buildGet: buildGetAll, buildPut: buildPutAll } = routesBuilder<NEWBuilding>()({ basePath: baseAllPath })
const { buildGet: buildGetSimilar } = routesBuilder<SimilarAddressBuildings>()({
  basePath: baseAllPath,
})

export const NEWBuildingRoutes = {
  readOneNEWBuilding: buildGet(),
  readAllNEWBuildings: buildGetAll({
    path: '/search/new',
    paginated: true,
    queryParamsSchema: paginationSearchStringQueryParamsSchema,
  }),
  readAllSplitNEWBuildings: buildGetSimilar({
    path: '/split/building',
    response: null as SplitBuilding[],
  }),
  readAllMergeNEWBuildings: buildGetSimilar({
    path: '/merge/building',
    responseIsArray: true,
    queryParamsSchema: thresholdQueryParamsSchema,
  }),
  mergeBuildings: buildPutAll({
    path: '/merge-buildings',
    payloadSchema: mergeBuildingsPayloadSchema,
  }),
  splitBuildings: buildPutAll({
    path: '/split-buildings',
    payloadSchema: splitBuildingsPayloadSchema,
  }),
}
