import { buildWebAppRoutes } from '@guiker/shared-framework'

import { config } from '../config'

export const { routes: officeRoutes, pathBuilder: officePathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        buildings: {
          path: '/buildings',
          routes: {
            listBuildings: '/',
          },
        },
        developers: {
          path: `/developers`,
          routes: {
            listDevelopers: '/',
            createDeveloper: '/new',
            byId: {
              path: '/:developerId',
              routes: {
                updateDeveloper: '/',
                projects: {
                  path: '/projects',
                  routes: {
                    listProjects: '/',
                    createProject: '/new',
                    byId: {
                      path: '/:projectId',
                      routes: {
                        updateProject: '/',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  } as const,
  config.domainName,
)
