import { LeaseType } from '@guiker/base-entity'

import { Applicant } from './applicant'
import { BookingDocument } from './booking-document'
import { BookingLease } from './booking-lease'
import { BookingPayment } from './booking-payment'
import { BookingStatus } from './booking-status'
import { Listing } from './listing'
import { Price } from './price'
import { UnitManager } from './unit-manager'

type BookingStatusHistory = {
  status: BookingStatus
  changedAt: string
  changedBy: string // userId
}

type BookingPeriod = {
  from: string
  to: string
}

type BookingInfo = {
  price: Price
  period: BookingPeriod
  leaseType: LeaseType
}

type TimerCount = {
  expiresAt?: string
  startsAt?: string
}

type Timer = TimerCount & {
  extension?: {
    requestedAt: string
    requestedBy: string
  }
}

enum StepTypes {
  APPLICATION = 'application',
  ROOMMATE = 'roommate',
  DOCUMENT = 'document',
  LEASE = 'lease',
  PAYMENT = 'payment',
}

type HasEnabledStep = {
  [key in StepTypes]: boolean
}

type Booking = {
  id: string
  applicants: Applicant[]
  bookingPayment: BookingPayment
  hasEnabledStep: HasEnabledStep
  info: BookingInfo
  lease?: BookingLease
  documents?: BookingDocument[]
  listing: Listing
  optionId: number
  primaryApplicantUserId: string
  primaryUnitManagerUserId: string
  status: BookingStatus
  statusHistory: BookingStatusHistory[]
  unitManagers: UnitManager[]
  timer?: Timer
  createdAt: string
  updatedAt: string
}

export { Booking, BookingInfo, BookingPeriod, BookingStatusHistory, HasEnabledStep, StepTypes, Timer, TimerCount }
