/** @todo include new RENTAL_LISTING */
export enum SearchableEntities {
  BOOKING = 'BOOKING',
  INVOICE = 'INVOICE',
  PROP_RENTAL = 'PROP_RENTAL',
  USER = 'USER',

  /** @todo deprecated: update to work with new rental-listing  */
  LISTING = 'LISTING',
  /** @todo deprecated: remove after new rental-listing is fully implemented */
  LISTING_UNIT = 'LISTING_UNIT',
}
