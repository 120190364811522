import { generateEventsDefinition } from '@guiker/event'

import { NEWProperty, NEWPropertyOwnership, NEWUnit } from '../../entity'
import { baseEvent } from '../base'

type SyncedEventData = {
  legacyId: number
  property: NEWProperty
}

const basePropertyOwnershipEvent = {
  ...baseEvent,
  entity: 'PROPERTY_OWNERSHIP',
  data: null as unknown as NEWPropertyOwnership,
}

export const PropertyOwnershipEventsDefinition = generateEventsDefinition({
  ...basePropertyOwnershipEvent,
  eventTypes: {
    REPRESENTATIVES_UPDATED: 'REPRESENTATIVES_UPDATED',
    FINANCIAL_INFO_UPDATED: 'FINANCIAL_INFO_UPDATED',
    HOLDING_PERIOD_UPDATED: 'HOLDING_PERIOD_UPDATED',
    DEACTIVATED: 'DEACTIVATED',
    UPDATE_TO_REAL_OWNER: {
      type: 'UPDATE_TO_REAL_OWNER',
      eventData: null as {
        inviterName: string
      },
    },
    SYNCED: {
      type: 'SYNCED',
      eventData: null as SyncedEventData,
    },
    META_LEGACY_IDS_UPDATED: 'META_LEGACY_IDS_UPDATED',
    MERGE_PROPERTY_OWNERSHIP_DELETED: {
      type: 'MERGE_PROPERTY_OWNERSHIP_DELETED',
      eventData: null as {
        mainBuildingId: string
        upsertedUnit: NEWUnit
        upsertedPropertyId: string
        upsertedPropertyOwnership: NEWPropertyOwnership
      },
    },
  } as const,
})

export const Events = PropertyOwnershipEventsDefinition.events
export const EventTypes = PropertyOwnershipEventsDefinition.eventTypes
export type PropertyOwnershipEventTypes = typeof EventTypes
