import React, { createContext } from 'react'

import { useModal } from '@guiker/react-framework'

import { MicrodepositInstructionModal } from '../components'

type Context = {
  microdepositModal: {
    openModal: () => void
  }
}

const DirectDebitPADContext = createContext<Context>(undefined)

type DirectDebitPADContextProviderProps = React.PropsWithChildren

const DirectDebitPADContextProvider: React.FC<DirectDebitPADContextProviderProps> = ({ children }) => {
  const { isOpen, openModal, closeModal } = useModal()
  const value = {
    microdepositModal: {
      openModal,
    },
  }

  return (
    <DirectDebitPADContext.Provider value={value}>
      {children}
      <MicrodepositInstructionModal isOpen={isOpen} onClose={closeModal} />
    </DirectDebitPADContext.Provider>
  )
}

export { DirectDebitPADContext, DirectDebitPADContextProvider }
