import { GeoSpatialLocation } from '@guiker/base-entity'
import { FloorPlan, LeaseType, ListingPicture } from '@guiker/rental-listing-shared'
import { Address, CurrencyISO, WithTimestamps } from '@guiker/shared-framework'

type Status = 'LISTED' | 'UNLISTED'

type RentalListingPicture = ListingPicture & {
  label?: string
}

type Location = GeoSpatialLocation & {
  region: string
}

type Price = {
  currency: CurrencyISO
  min: number
  max?: number
  options?: {
    name: string
    amount: number
  }[]
}

export enum ListingType {
  'UnitRentalListing' = 'UnitRentalListing',
  'RoomRentalListing' = 'RoomRentalListing',
  'LayoutRentalListing' = 'LayoutRentalListing',
}

export type UnitRentalListing = RentalListing & {
  type: ListingType.UnitRentalListing
  unit: {
    id: string
    suite: number
    squareFootage?: number
    pictures: RentalListingPicture[]
  }
}

export type RoomRentalListing = RentalListing & {
  type: ListingType.RoomRentalListing
  squareFootage?: number
  unit: {
    id: string
    suite: number
    squareFootage?: number
    pictures: RentalListingPicture[]
  }
  room: {
    label: string
    squareFootage?: number
    pictures: RentalListingPicture[]
  }
}

export type LayoutRentalListing = RentalListing & {
  type: ListingType.LayoutRentalListing
  pictures: RentalListingPicture[]
}

export type RentalListing = WithTimestamps<{
  id: string
  sequentialId: number
  propRental: {
    id: string
  }
  slug: string
  availability?: string
  price: Price
  address: Omit<Address, 'cityName' | 'suite'>
  location: Location
  leaseType: LeaseType
  description: string
  floorPlan: FloorPlan
  pictures: ListingPicture[]
  status: Status
}>
