import React from 'react'

import {
  AuthButtonContainer,
  InvoiceDetail,
  InvoiceDetailPaymentMethod,
  PayInMethodLabel,
  ViewInvoiceLayout,
  WireTransferInstruction,
} from '@guiker/payment-app-components'
import { usePayInMethodContext } from '@guiker/payment-context'
import { Invoice, ScopeType, webappRoutes } from '@guiker/payment-shared'
import { Button, Flex, Link, Modal, Note, P, PSmall, useModal, useT } from '@guiker/react-framework'
import { getUserFullName, last, User } from '@guiker/shared-framework'

import { useInvoiceContext } from '../../hooks'
import { useTransformEventsToActivityItems } from './utils'

const WireTransferInstructionButton: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const { tShared } = useT({ domain: 'payment', entity: 'wireTransfer' })

  return (
    <>
      <Modal maxWidth={900} title={tShared('title')} open={isOpen} onClose={() => closeModal()}>
        <WireTransferInstruction scopeType={invoice.scope?.type as ScopeType} />
      </Modal>
      <Button onClick={() => openModal()} size='medium' fullWidth>
        {tShared('cta')}
      </Button>
    </>
  )
}

export const InvoiceContent: React.FC<{ user?: User }> = ({ user }) => {
  const { tBase, tShared, tMain } = useT({ domain: 'payment', screenName: 'invoiceContent' })
  const { invoice, invoiceState, events } = useInvoiceContext()
  const { selected } = usePayInMethodContext()
  const { activityLogItems, dispute } = useTransformEventsToActivityItems({ invoice, events })

  const isPayer = invoice?.customer.id === user?.id
  if (!isPayer) return <></>

  const customerName = getUserFullName(invoice.customer)
  const payInMethod = last(invoice.transactions)?.payInMethod

  return (
    <ViewInvoiceLayout invoice={invoice}>
      {invoiceState.isPendingWireTransfer && <InvoiceDetail.WireTransferNotice />}

      {dispute.exists && dispute.recoveryInvoice && (
        <Note title={tMain('dispute.recovery.label')}>
          <Flex flexDirection='column' gap={2}>
            <P>
              {tMain('dispute.recovery.description', {
                reason: tShared(`invoice.disputed.reason.${invoice.dispute.reason}`),
              })}
            </P>
            <Flex fullWidth justifyContent='flex-end'>
              <Link to={webappRoutes.root.invoice.byId(dispute.recoveryInvoice.id).path}>
                <Button>{tBase('actions.continue')}</Button>
              </Link>
            </Flex>
          </Flex>
        </Note>
      )}

      <InvoiceDetail.InvoiceDetailInfo invoice={invoice}>
        <InvoiceDetail.DetailContent
          left={{
            label: tShared('info.recipient'),
            value: [<P>{customerName}</P>, <PSmall color={60}>{invoice.customer.emailAddress}</PSmall>],
          }}
          right={
            payInMethod
              ? {
                  label: tShared('info.method'),
                  value: <PayInMethodLabel payInMethod={payInMethod} />,
                }
              : undefined
          }
        />
      </InvoiceDetail.InvoiceDetailInfo>
      <InvoiceDetail.ItemBreakdown invoice={invoice} />

      <InvoiceDetail.ActivityLog items={activityLogItems} />

      {invoiceState.isPayable && (
        <Flex flexDirection='column' gap={4}>
          <InvoiceDetailPaymentMethod />
          <AuthButtonContainer invoice={invoice} payInMethod={selected} />
        </Flex>
      )}
      {invoiceState.isPendingWireTransfer && <WireTransferInstructionButton invoice={invoice} />}
    </ViewInvoiceLayout>
  )
}
