import { buildPaginationQueryParamSchema, CountryCode, Paginate, ValueOfUnion, yup } from '@guiker/shared-framework'

import { RentCollectionStatus, RentPayoutStatus } from '../../../entity'
import { getYearMonthSchema } from '../../schema-utils'

const dateSchema = getYearMonthSchema('date')

const schema = dateSchema.concat(
  yup
    .object({
      country: yup.mixed<CountryCode>().required(),
      listingId: yup.string().nullable(),
      unitId: yup.string().nullable(),
    })
    .required(),
)

export const readRentCollectionPerTenantTableQueryParams = schema
  .concat(
    yup.object({
      statuses: yup
        .array(yup.string().oneOf(Object.values(RentCollectionStatus)))
        .nullable()
        .default([]),
    }),
  )
  .concat(buildPaginationQueryParamSchema({}))

export const readRentCollectionPerPayoutTableQueryParams = dateSchema
  .concat(
    yup.object({
      statuses: yup
        .array(yup.string().oneOf(Object.values({ ...RentPayoutStatus })))
        .nullable()
        .default([]),
      payoutMethodIds: yup.array(yup.string()).nullable().default([]),
    }),
  )
  .concat(buildPaginationQueryParamSchema({}))

export const readRentCollectionCSVDataQueryParams = schema.concat(
  yup.object({
    statuses: yup
      .array(yup.string().oneOf(Object.values(RentCollectionStatus)))
      .nullable()
      .default([]),
  }),
)

export type ReadRentCollectionPerTenantTableQueryParams = Partial<Paginate> & {
  date: string
  country: CountryCode
  statuses: RentCollectionStatus[]
  listingId: string
}

export type ReadRentCollectionPerPayoutTableQueryParams = Partial<Paginate> & {
  date: string
  statuses: ValueOfUnion<RentPayoutStatus>[]
}

export type ReadRentCollectionCSVDataQueryParams = {
  date: string
  country: CountryCode
  statuses: RentCollectionStatus[]
  listingId: string
}
