import { last } from '@guiker/shared-framework'

import { Dispute, DisputeReason, Invoice, InvoiceStatus, PayInMethodType, Scopeless, ScopeType } from '../entity'

export const FAILED_STATUS: InvoiceStatus[] = [
  InvoiceStatus.PAYMENT_FAILED,
  InvoiceStatus.REFUND_FAILED,
  InvoiceStatus.VERIFICATION_FAILED,
]
export const PAYABLE_STATUS: InvoiceStatus[] = [
  InvoiceStatus.SENT,
  InvoiceStatus.PAYMENT_FAILED,
  InvoiceStatus.VERIFICATION_FAILED,
]
export const PROCESSING_STATUS: InvoiceStatus[] = [
  InvoiceStatus.PENDING,
  InvoiceStatus.VERIFICATION_REQUESTED,
  InvoiceStatus.VERIFICATION_SUCCEED,
  InvoiceStatus.REFUND_PENDING,
]
export const SUCCESS_STATUS: InvoiceStatus[] = [InvoiceStatus.PAID, InvoiceStatus.SETTLED]
export const CANCELABLE_STATUS: InvoiceStatus[] = [...PAYABLE_STATUS, InvoiceStatus.DRAFTED]
export const IMMUTABLE_STATUS: InvoiceStatus[] = [
  ...PROCESSING_STATUS,
  InvoiceStatus.CANCELED,
  InvoiceStatus.REFUND_PENDING,
]
export const INCOMPLETE_STATUS: InvoiceStatus[] = [InvoiceStatus.DRAFTED]

export const isInvoicePayable = (invoice: Pick<Invoice, 'status'>) => {
  return PAYABLE_STATUS.includes(invoice?.status)
}

export const isInvoiceIncomplete = (invoice: Pick<Invoice, 'status'>) => {
  return INCOMPLETE_STATUS.includes(invoice?.status)
}

export const isInvoiceRefundable = (invoice: Pick<Invoice, 'status' | 'transactions'>) => {
  const lastTransaction = last(invoice.transactions)
  const isRefundablePaidMethod = lastTransaction?.payInMethod?.type !== PayInMethodType.DIRECT_DEBIT_EFT
  return (
    !IMMUTABLE_STATUS.includes(invoice?.status) && isRefundablePaidMethod && SUCCESS_STATUS.includes(invoice?.status)
  )
}

export const isInvoiceCancelable = (invoice: Pick<Invoice, 'status'>) => {
  return CANCELABLE_STATUS.includes(invoice?.status)
}

export const isInvoiceFailed = (invoice: Pick<Invoice, 'status'>) => {
  return FAILED_STATUS.includes(invoice?.status)
}

export const isInvoiceSuccess = (invoice: Pick<Invoice, 'status'>) => {
  return SUCCESS_STATUS.includes(invoice?.status)
}

export const isInvoiceProcessing = (invoice: Pick<Invoice, 'status'>) => {
  return PROCESSING_STATUS.includes(invoice?.status)
}

export const isInvoiceImmutable = (invoice: Pick<Invoice, 'status'>) => {
  return IMMUTABLE_STATUS.includes(invoice?.status)
}

export const invoiceTypeChecker = {
  scope: {
    isTenantInstalment: (invoice: Invoice): invoice is Invoice<ScopeType.TENANT_INSTALMENT> => {
      return invoice.scope?.type === ScopeType.TENANT_INSTALMENT
    },
    isBuyStockOrder: (invoice: Invoice): invoice is Invoice<ScopeType.BUY_STOCK_ORDER> => {
      return invoice.scope?.type === ScopeType.BUY_STOCK_ORDER
    },
    isFundingAccountDeposit: (invoice: Invoice): invoice is Invoice<ScopeType.FUNDING_ACCOUNT_DEPOSIT> => {
      return invoice.scope?.type === ScopeType.FUNDING_ACCOUNT_DEPOSIT
    },
    isInvoiceDisputed: (invoice: Invoice): invoice is Invoice<ScopeType.DISPUTED_INVOICE> => {
      return invoice.scope?.type === ScopeType.DISPUTED_INVOICE
    },
    isNone: (invoice: Invoice): invoice is Invoice<Scopeless> => {
      return !invoice.scope
    },
  },
}

export const isInvoiceRecoverable = (invoice: Invoice) => {
  const { dispute } = invoice
  const disputeReason = dispute?.reason
  return invoice?.scope?.type === ScopeType.TENANT_INSTALMENT && disputeReason !== DisputeReason.PAYER_DISPUTE
}

export const isDisputePayerInitiated = (dispute: Dispute) => {
  return dispute.reason === DisputeReason.PAYER_DISPUTE
}
