import { routesBuilder, StatusCodes } from '@guiker/shared-framework'

import { paths } from '../paths'
import { rentPaymentReconciliationQueryParams } from './schemas'

const { buildGet } = routesBuilder<object>()({ basePath: paths.statistic.all.base() })

export const routes = {
  exportRentPaymentsReconciliation: buildGet({
    path: '/rent-payments/export-reconciliation',
    queryParamsSchema: rentPaymentReconciliationQueryParams,
    successStatusCode: StatusCodes.REDIRECT,
    response: null as {},
  }),
}
