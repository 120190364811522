import React from 'react'

import { Booking, UnitManager } from '@guiker/booking-shared'
import { Chip, Divider, Flex } from '@guiker/react-framework'
import { getUserFullName } from '@guiker/shared-framework'

import { UserInformation } from './UserInformation'

type UnitManagerListProps = {
  booking: Booking
}

type UnitManagerListItemProps = {
  unitManager: UnitManager
}

export const UnitManagerList: React.FC<UnitManagerListProps> = ({ booking }) => {
  return (
    <Flex flexDirection='column' gap={1}>
      {booking.unitManagers.map((unitManager, index) => (
        <>
          <UnitManagerListItem unitManager={unitManager} />
          {index < booking.unitManagers.length - 1 && <Divider />}
        </>
      ))}
    </Flex>
  )
}

export const UnitManagerListItem: React.FC<UnitManagerListItemProps> = ({ unitManager }) => {
  const roleName = unitManager.role?.replace('_', ' ')

  return (
    <Flex fullWidth justifyContent='space-between'>
      <UserInformation
        firstName={unitManager.firstName}
        lastName={unitManager.lastName}
        avatarUrl={unitManager.avatarUrl}
        emailAddress={unitManager.emailAddress}
        userId={unitManager.userId}
        fullName={getUserFullName(unitManager)}
      />
      <Chip color='info' size='small' variant='outlined'>
        {roleName}
      </Chip>
    </Flex>
  )
}
