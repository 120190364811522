import { routesBuilder } from '@guiker/shared-framework'

import { Transfer } from '../../../entity'
import { paths } from '../paths'
import { createTransferPayloadSchema } from '../schemas'

const base = routesBuilder<Transfer>()({
  basePath: paths.dynamic.transfer.all.withScope(),
})

export const transferRoutes = {
  createTransfer: base.buildPost({
    payloadSchema: createTransferPayloadSchema,
  }),
  markTransferPaidOffline: base.buildPut({
    path: '/mark-paid-offline',
  }),
}
