import { AuthLevel, buildPaths } from '@guiker/shared-framework'

export const paths = buildPaths(
  {
    propRental: {
      manager: {},
    },
    listing: {
      propRental: {},
    },
    user: {
      rentalServiceFee: {},
    },
  },
  AuthLevel.ADMIN,
)
