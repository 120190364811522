import { buildQueueDefinition } from '@guiker/queue'

import { config } from '../config'
import { InvestorProfile, StockOrder, StockOrderExecution, WithLiquidatedStock } from '../entity'

export const Queue = {
  ProcessSeriesCloseInitiated: buildQueueDefinition({
    name: 'ProcessSeriesCloseInitiated',
    serviceName: config.apiName,
    payload: null as {
      stockId: string
      userId: string
      ticker: string
      name: string
      quantity: number
      unitPrice: string
      purchaseValue: string
      purchaseDate: string
    }[],
    isFifo: true,
    batchSize: 1,
  }),
  ProcessShareIssuance: buildQueueDefinition({
    name: 'ProcessStockIssuance',
    serviceName: config.apiName,
    payload: null as StockOrderExecution[],
    isFifo: true,
    batchSize: 1,
  }),
  IssuerInitiatedCancelReservedOrders: buildQueueDefinition({
    name: 'IssuerInitiatedCancelReservedOrders',
    serviceName: config.apiName,
    payload: null as StockOrder[],
    isFifo: false,
    batchSize: 1,
  }),
  BuyoutLiquidatedStock: buildQueueDefinition({
    name: 'BuyoutLiquidatedStock',
    serviceName: config.apiName,
    payload: null as WithLiquidatedStock<InvestorProfile>[],
    isFifo: false,
    batchSize: 1,
  }),
} as const
