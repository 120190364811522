import { buildPaginationQueryParamSchema } from '@guiker/paginate'
import { yup } from '@guiker/yup-util'

export const paginationSearchStringQueryParamsSchema = buildPaginationQueryParamSchema().concat(
  yup.object<{
    searchString?: string
  }>({
    searchString: yup.string().nullable(),
  }),
)
