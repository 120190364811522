import React from 'react'

import { Flex, Link, P, PSmall } from '@guiker/react-framework'

import { ParticipantAvatar } from '../BookingPanel/components/Participants/ParticipantAvatar'

type UserListItemProps = {
  userId: string
  firstName: string
  lastName: string
  fullName: string
  emailAddress: string
  avatarUrl?: string
}

export const UserInformation: React.FC<UserListItemProps> = ({
  userId,
  firstName,
  lastName,
  fullName,
  avatarUrl,
  emailAddress,
}) => {
  return (
    <Flex gap={2} alignItems='center' flexGrow={1} alignSelf='flex-start'>
      <ParticipantAvatar size='medium' firstName={firstName} lastName={lastName} avatarUrl={avatarUrl} />
      <Link to={`/user/${userId}`} prefixWithLocale={false}>
        <Flex flexDirection='column' gap={1} flexGrow={1}>
          <P>{fullName}</P>
          <PSmall color={60}>{emailAddress}</PSmall>
        </Flex>
      </Link>
    </Flex>
  )
}
