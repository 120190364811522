import { routesBuilder } from '@guiker/api-definition'
import { RecommendedListing } from '@guiker/recommended-listing-entity'

import { paths } from './paths'

const { buildGet } = routesBuilder<RecommendedListing>()({ basePath: paths.recommendedListings.all.base() })

export const routes = {
  readAllRecommendedListingsByListingId: buildGet({
    path: '/listings/:listingId',
    paginated: true,
  }),
}
