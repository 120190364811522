import React, { useEffect } from 'react'

import { PayInMethodType } from '@guiker/payment-shared'
import { CountryCode, StripeLink } from '@guiker/payment-verification-components'
import {
  Box,
  Flex,
  Payments,
  PSmall,
  TextField,
  TwoColumnsGridLayout,
  useApiFormAction,
  useFormContext,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'

import { useDirectDebitPADContext } from '../../../hooks'

type DirectDebitPADCreationFormType = {
  isProcessing: boolean
  closeForm: () => void
  isManualInput?: boolean
}

export const DirectDebitPADCreationForm: React.FC<DirectDebitPADCreationFormType> = ({
  closeForm,
  isManualInput = false,
}) => {
  const tPrefix = 'common-payment:directDebit'
  const { t } = useTranslation(['common, common-payment'])
  const bankAccountId = useWatch({ name: 'bankAccountId', defaultValue: null })
  const formContext = useFormContext()
  const apiFormAction = useApiFormAction()
  const { microdepositModal } = useDirectDebitPADContext()

  const handleOnSelect = ({
    publicToken,
    accountId,
    hasMicrodeposit,
  }: {
    publicToken: string
    accountId: string
    hasMicrodeposit: boolean
  }) => {
    formContext.setValue(`publicToken`, publicToken)
    formContext.setValue(`bankAccountId`, accountId)
    formContext.setValue(`payInMethodType`, PayInMethodType.DIRECT_DEBIT_PAD)
    hasMicrodeposit && microdepositModal.openModal()
  }

  useEffect(() => {
    bankAccountId && apiFormAction.onSubmit()
  }, [bankAccountId])

  return (
    <Flex flexDirection='column' gap={2}>
      {isManualInput ? (
        <>
          <PSmall color={60}>{t(`${tPrefix}.EFT.theFollowingInformation`)}</PSmall>
          <TwoColumnsGridLayout>
            <TextField required label={t(`${tPrefix}.EFT.institutionNumber`)} name='bankAccount.institutionId' />
            <TextField required label={t(`${tPrefix}.EFT.transitNumber`)} name='bankAccount.transitNumber' />
            <TextField required label={t(`${tPrefix}.EFT.accountNumber`)} name='bankAccount.accountNumber' />
          </TwoColumnsGridLayout>
        </>
      ) : (
        <>
          <Box display='none'>
            <TextField type='hidden' name='bankAccountId' />
            <TextField type='hidden' name='publicToken' />
            <TextField type='hidden' name='payInMethodType' />
          </Box>

          <Flex gap={1} flexWrap='nowrap' alignItems='center'>
            <Payments.Bank.BankIcon width={54} height={36} />
            <StripeLink
              countryCode={CountryCode.Canada}
              label={t('common-payment:connector.link')}
              onSelect={handleOnSelect}
              onError={() => closeForm()}
            />
          </Flex>
        </>
      )}
    </Flex>
  )
}
