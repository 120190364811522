import { optionalConcat } from '@guiker/lodash'

import { PropRentalProjection } from '../entity'

export const getPropertyAddress = (property: PropRentalProjection['propertyOwnership']['property']) => {
  const { mailingAddress: address } = property.building

  return {
    ...address,
    suite: property.unit?.suite,
    streetNumber: optionalConcat(address.streetNumbers, '-'),
    postalCode: address.zipcode,
  }
}
