import { generateEventsDefinition } from '@guiker/event'

import { RentalServiceFee } from '../../entity'
import { baseEvent } from '../base'

const baseRentalServiceFeeEvent = {
  ...baseEvent,
  entity: 'RENTAL_SERVICE_FEE',
  data: null as RentalServiceFee,
}

export const RentalServiceFeeEventsDefinition = generateEventsDefinition({
  ...baseRentalServiceFeeEvent,
})

export const Events = RentalServiceFeeEventsDefinition.events
export const EventTypes = RentalServiceFeeEventsDefinition.eventTypes
export type RentalServiceFeeEventTypes = typeof EventTypes
