import React from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { ServiceFeeType } from '@guiker/booking-shared'
import { DataTrackingCTA } from '@guiker/data-tracking-context'
import { Payout } from '@guiker/payout-app-components'
import { ButtonWithLoader, ConfirmDialog, Flex, KeyValuePair, P, PSmall, PSmaller } from '@guiker/react-framework'
import { addressFormatter, formatDate, money, optionalConcat } from '@guiker/shared-framework'

import { useT } from '../../i18n'

type ConfirmBookingModalProps = {
  isLoading: boolean
  open: boolean
  onClose: () => void
  handleConfirmation: () => void
}

const BookingConfirmationContent: React.FC = () => {
  const { booking } = useBookingContext()
  const { tMain } = useT({ screenName: 'confirmBookingModal.labels' })
  const currency = booking.info.price.currency
  const serviceFee = booking.bookingPayment.serviceFee
  const isServiceFeePercentage = serviceFee.type === ServiceFeeType.PERCENTAGE

  return (
    <Flex flexDirection='column' gap={2} fullWidth>
      <KeyValuePair
        keyName={tMain('tenant')}
        value={
          <Flex flexDirection='column' gap={1}>
            {booking.applicants.map((a) => (
              <PSmall>{optionalConcat([a.firstName, a.lastName])}</PSmall>
            ))}
          </Flex>
        }
      />
      <KeyValuePair keyName={tMain('address')} value={addressFormatter.printShortAddress(booking.listing.address)} />
      <KeyValuePair keyName={tMain('startDate')} value={formatDate(booking.info.period.from)} />
      <KeyValuePair keyName={tMain('endDate')} value={formatDate(booking.info.period.to)} />
      <KeyValuePair keyName={tMain('price')} value={money.fromAmount(booking.info.price.amount, currency).toString()} />
      {booking.bookingPayment.payoutMethod && (
        <KeyValuePair
          keyName={tMain('destinationAccount')}
          value={<Payout.PayoutMethodLabel payoutMethod={booking.bookingPayment.payoutMethod} />}
        />
      )}
      <KeyValuePair
        keyName={tMain('serviceFee')}
        value={
          isServiceFeePercentage ? `${serviceFee.value}%` : money.fromAmount(serviceFee.value, currency).toString()
        }
      />
      <Flex flexDirection='column'>
        <KeyValuePair
          keyName={tMain('payment.label')}
          value={tMain(`payment.${booking.bookingPayment.condition}.label`)}
        />
        <PSmaller>{tMain(`payment.${booking.bookingPayment.condition}.desc`)}</PSmaller>
      </Flex>
    </Flex>
  )
}

export const ConfirmBookingModal: React.FC<ConfirmBookingModalProps> = (props) => {
  const { open, onClose, isLoading, handleConfirmation } = props
  const { tMain } = useT({ screenName: 'confirmBookingModal' })

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={tMain('title')}
      description={
        <Flex fullWidth flexDirection='column' gap={3}>
          <BookingConfirmationContent />
          <P textAlign='right'>{tMain('actions.title')}</P>
        </Flex>
      }
      cancelButtonProps={{
        onClick: () => onClose(),
        children: tMain('actions.cancel'),
      }}
    >
      <DataTrackingCTA key={'ConfirmBooking'}>
        <ButtonWithLoader fullWidth isLoading={isLoading} onClick={() => handleConfirmation()}>
          {tMain('actions.confirm')}
        </ButtonWithLoader>
      </DataTrackingCTA>
    </ConfirmDialog>
  )
}
