import { generateEventsDefinition } from '@guiker/event'

import { NEWBuilding, NEWUnit } from '../../entity'
import { baseEvent } from '../base'

type SyncedEventData = {
  legacyId: number
  building: NEWBuilding
}

const baseUnitEvent = {
  ...baseEvent,
  entity: 'UNIT',
  data: null as unknown as NEWUnit,
}

export const UnitEventsDefinition = generateEventsDefinition({
  ...baseUnitEvent,
  eventTypes: {
    SYNCED: {
      type: 'SYNCED',
      eventData: null as SyncedEventData,
    },
    MERGE_UNIT_DELETED: {
      type: 'MERGE_UNIT_DELETED',
      eventData: null as {
        mainBuildingId: string
        upsertedUnit: NEWUnit
        legacyIds: number[]
      },
    },
  } as const,
})

export const Events = UnitEventsDefinition.events
export const EventTypes = UnitEventsDefinition.eventTypes
export type UnitEventTypes = typeof EventTypes
