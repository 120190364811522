import { TypeOf, yup } from '@guiker/yup-util'

export const mergeBuildingsPayloadSchema = yup.object<{
  mainBuildingId: string
  buildingIds: string[]
}>({
  mainBuildingId: yup.string().required(),
  buildingIds: yup.array(yup.string()).min(1).required(),
})

export type MergeBuildingsPayloadSchema = TypeOf<typeof mergeBuildingsPayloadSchema>
