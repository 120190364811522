import { AuthLevel, generateApiPath } from '@guiker/api-definition'
const developer = generateApiPath({ prefix: AuthLevel.ADMIN, name: 'developer' })

const paths = {
  buildings: generateApiPath({ prefix: AuthLevel.ADMIN, name: 'building' }),
  property: generateApiPath({
    prefix: AuthLevel.ADMIN,
    name: 'property',
    plural: 'properties',
  }),
  propertyOwner: generateApiPath({ prefix: AuthLevel.ADMIN, name: 'property-ownership/owner' }),
  propertyOwnership: generateApiPath({ prefix: AuthLevel.ADMIN, name: 'property-ownership' }),
  NEWPropertyOwnership: generateApiPath({ prefix: AuthLevel.ADMIN, name: 'NEWProperty-ownership' }),
  NEWBuilding: generateApiPath({ prefix: AuthLevel.ADMIN, name: 'NEWBuilding' }),
  NEWProperty: generateApiPath({ prefix: AuthLevel.ADMIN, name: 'NEWProperty', plural: 'NEWProperties' }),
  developer,
  developerNestedProject: generateApiPath({ prefix: developer.all.withId(':developerId'), name: 'project' }),
}

export { paths }
