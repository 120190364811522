import { RentalListing } from '@guiker/rental-listing-shared'
import { routesBuilder } from '@guiker/shared-framework'

import { PropRental, PropRentalProjection } from '../../../../entity'
import { paths } from '../../paths'
import { leaseTypePayloadSchema } from '../../schemas'
import { transferOwnershipSchema, updatePropRentalPayoutMethodSchema } from '../schemas'
import { routes as managersRoutes } from './managers'

const { buildGet, buildPut, buildPost } = routesBuilder<PropRental>()({ basePath: paths.propRentals.withId.path })

export const routes = {
  ...managersRoutes,
  readOnePropRental: buildGet({
    response: null as PropRentalProjection,
  }),
  updatePropRentalServiceFee: buildPut({
    path: '/service-fee',
    payloadSchema: leaseTypePayloadSchema,
  }),
  readAllRentalListingsByPropRentalId: buildGet({
    path: '/rental-listings',
    response: [] as RentalListing[],
  }),
  updatePropRentalPayoutMethod: buildPut({
    path: '/update-payout-method',
    payloadSchema: updatePropRentalPayoutMethodSchema,
  }),
  transferOwnership: buildPost({
    path: '/transfer-ownership',
    payloadSchema: transferOwnershipSchema,
  }),
}
