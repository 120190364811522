import { buildQueueDefinition } from '@guiker/queue'
import { NEWProperty, NEWPropertyOwnership } from '@guiker/real-estate-shared'

import { config } from '../config'

export const Queue = {
  RequeuePropertyOwnershipSync: buildQueueDefinition({
    name: 'RequeuePropertyOwnershipSync',
    serviceName: config.apiName,
    payload: null as {
      legacyId: number
      property: NEWProperty
      propertyOwnership: NEWPropertyOwnership
    }[],
    isFifo: true,
    batchSize: 1,
  }),
} as const
