import { PhoneableCountryCode, PhoneNumberSchema, yup } from '@guiker/shared-framework'

import { ManagerRole } from '../../../../../entity'

type PropRentalManager = {
  firstName: string
  lastName?: string
  title?: string
  phone?: {
    country: PhoneableCountryCode
    number: string
  }
  isContactPerson: boolean
  role?: ManagerRole
}

type AddPropRentalManager = PropRentalManager & {
  userId?: string
  emailAddress: string
}
export const addPropRentalManager = yup.object<AddPropRentalManager>({
  userId: yup.string().nullable(),
  firstName: yup.string().required(),
  lastName: yup.string().nullable(),
  emailAddress: yup.string().email().required(),
  phone: PhoneNumberSchema(false),
  isContactPerson: yup.boolean().required(),
  role: yup.string().oneOf(Object.values(ManagerRole)).nullable(),
})

type UpdatePropRentalManager = PropRentalManager & {
  userId: string
  firstName?: string
  lastName?: string
}
export const updatePropRentalManager = yup.object<UpdatePropRentalManager>({
  userId: yup.string().required(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  title: yup.string().nullable(),
  phone: PhoneNumberSchema(false),
  isContactPerson: yup.boolean().required(),
  role: yup.string().oneOf(Object.values(ManagerRole)).nullable(),
})
