import { buildWebAppRoutes } from '@guiker/shared-framework'

import { config } from '../config'

export const { routes: officeRoutes, pathBuilder: officePathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        list: '/',
        owners: {
          path: '/owners',
          routes: {
            list: '/',
            byUserId: {
              path: '/:userId',
              routes: {
                properties: '/properties',
                bookings: {
                  path: '/bookings',
                  routes: {
                    list: '/',
                    byId: {
                      path: '/:bookingId',
                      routes: {
                        one: '/',
                      },
                    },
                  },
                },
                rentalServiceFee: '/rental-service-fee',
              },
            },
          },
        },
        byId: {
          path: '/:id',
          routes: {
            one: '/',
            overview: '/overview',
            payoutMethods: '/payout-methods',
            owner: '/owner',
            managers: '/managers',
            rentalServiceFee: '/rental-service-fee',
            listings: {
              path: '/listings',
              routes: {
                list: '/',
                byId: {
                  path: '/:listingId',
                  routes: {
                    one: '/',
                  },
                },
              },
            },
            bookings: {
              path: '/bookings',
              routes: {
                list: '/',
                byId: {
                  path: '/:bookingId',
                  routes: {
                    one: '/',
                  },
                },
              },
            },
          },
        },
      },
    },
  } as const,
  config.domainName,
)
