import { webappRoutes as accountWebappRoutes } from '@guiker/account-shared'
import { addressFormatter } from '@guiker/base-entity'
import { mainPathBuilder as conversationPathBuilder } from '@guiker/conversation-shared'
import { mainPathBuilder } from '@guiker/my-investment-shared'
import {
  BookingNotification,
  BookingNotificationMetadata,
  Notification,
  NotificationTypes,
} from '@guiker/notification-shared'
import { DirectDebitPADPayInMethod } from '@guiker/payment-shared'
import { StockOrder, StockOrderExecution } from '@guiker/propsharing-shared'
import { useDateFormatter } from '@guiker/react-framework'

type ParsedMetadata = {
  isExternalLink?: boolean
  url?: string
  primaryTextParams?: object
  secondaryTextParams?: object
}

export const useMetadataParser = () => {
  const { formatWithRelativeDateTime } = useDateFormatter()

  return {
    parse: (notification: Notification): ParsedMetadata => {
      const { context, entity, type } = notification.type

      switch (context) {
        case NotificationTypes.BOOKING.context:
          switch (entity) {
            case NotificationTypes.BOOKING.BOOKING.entity:
              switch (type) {
                case NotificationTypes.BOOKING.BOOKING.INQUIRY_CREATED.type:
                case NotificationTypes.BOOKING.BOOKING.LEASE_READY_TO_BE_SIGNED.type:
                case NotificationTypes.BOOKING.BOOKING.APPLICATION_CHANGE_REQUESTED.type:
                case NotificationTypes.BOOKING.BOOKING.LEASE_TERM_EXTENDED.type:
                case NotificationTypes.BOOKING.BOOKING.EARLY_TERMINATION_SET.type:
                  const metadata = notification.metadata as BookingNotificationMetadata<BookingNotification>
                  const bookingId = metadata.bookingId as string

                  return {
                    isExternalLink: true,
                    url: conversationPathBuilder.root.messages.path({ bookingId }),
                    primaryTextParams: {
                      listingAddress: addressFormatter.printShortAddress(metadata.listingAddress),
                      endDate: metadata.period ? formatWithRelativeDateTime(metadata.period.to) : undefined,
                    },
                  }
                default:
                  return {}
              }
            default:
              return {}
          }
        case NotificationTypes.PAYOUT.PAYOUT_METHOD:
          switch (entity) {
            case NotificationTypes.PAYOUT.PAYOUT_METHOD.entity:
              switch (type) {
                case NotificationTypes.PAYOUT.PAYOUT_METHOD.ENABLED.type:
                  return {
                    url: accountWebappRoutes.root.wallet().payoutPreferences().path,
                  }
                default:
                  return {}
              }
            default:
              return {}
          }
        case NotificationTypes.WILLOW.context:
          switch (entity) {
            case NotificationTypes.WILLOW.MIGRATION.entity:
              const metadata = notification.metadata as { stockTickerSymbol: string }
              return {
                url: mainPathBuilder.root.investmentDashboard.path,
                primaryTextParams: { stock: metadata?.stockTickerSymbol },
                secondaryTextParams: { stock: metadata?.stockTickerSymbol },
              }
          }
        case NotificationTypes.PAYMENT.context:
          switch (entity) {
            case NotificationTypes.PAYMENT.PAY_IN_METHOD.entity:
              return {
                primaryTextParams: {
                  accountNumber: (notification.metadata.payInMethod as DirectDebitPADPayInMethod).details.accountNumber,
                },
              }
          }
        case NotificationTypes.FRACTIONAL_OWNERSHIP.context:
          switch (entity) {
            case NotificationTypes.FRACTIONAL_OWNERSHIP.INVESTOR_PROFILE.entity:
              switch (type) {
                case NotificationTypes.FRACTIONAL_OWNERSHIP.INVESTOR_PROFILE.APPROVED.type:
                  return {
                    url: mainPathBuilder.root.investmentDashboard.path,
                  }
                case NotificationTypes.FRACTIONAL_OWNERSHIP.INVESTOR_PROFILE.TAX_SLIP_ADDED.type:
                  return {
                    url: accountWebappRoutes.root.documents().path,
                  }
                default:
                  return {}
              }
            case NotificationTypes.FRACTIONAL_OWNERSHIP.STOCK_ORDER.entity:
              switch (type) {
                case NotificationTypes.FRACTIONAL_OWNERSHIP.STOCK_ORDER.BUYOUT.type:
                  return {
                    url: '/account/wallet/funding-account',
                    primaryTextParams: {
                      unitNumber: (notification.metadata.share as StockOrder['share']).quantity,
                      stockLabel: (notification.metadata.stock as StockOrder['stock']).label,
                    },
                  }
                default:
                  return {}
              }
            case NotificationTypes.FRACTIONAL_OWNERSHIP.STOCK_ORDER_EXECUTION.entity:
              switch (type) {
                case NotificationTypes.FRACTIONAL_OWNERSHIP.STOCK_ORDER_EXECUTION.FULFILLED.type:
                  return {
                    url: mainPathBuilder.root.investmentDashboard.path,
                    primaryTextParams: {
                      unitNumber: (notification.metadata.price as StockOrderExecution['price']).quantity,
                      stockLabel: (notification.metadata.stock as StockOrderExecution['stock']).label,
                    },
                  }
                default:
                  return {}
              }

            default:
              return {}
          }

        default:
          return {}
      }
    },
  }
}
