import { CountryCode, TypeOf, yup } from '@guiker/shared-framework'

import { getYearMonthSchema } from '../../schema-utils'

export const kpiQueryParams = getYearMonthSchema('month').concat(
  yup
    .object({
      country: yup.mixed<CountryCode>().required(),
      listingId: yup.string().nullable().optional(),
      unitId: yup.string().nullable().optional(),
    })
    .required(),
)

export type KpiQueryParams = TypeOf<typeof kpiQueryParams>
