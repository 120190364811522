import { routesBuilder } from '@guiker/api-definition'
import { RecommendedListing } from '@guiker/recommended-listing-entity'

import { buildListingIdQueryParams } from './build-listing-id-query-param-schema'
import { paths } from './paths'

const { buildGet } = routesBuilder<RecommendedListing>()({ basePath: paths.recommendedListing.all.base() })

export const routes = {
  readAllRecommendedListings: buildGet({
    paginated: true,
    queryParamsSchema: buildListingIdQueryParams(),
  }),
  readAllRecommendedListingsByListingId: buildGet({
    path: '/listings/:listingId',
    paginated: true,
  }),
  readAllRecommendedListingsByTenantPreferences: buildGet({
    path: '/tenant-preference',
    paginated: true,
  }),
}
